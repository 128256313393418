<template>
    <div @click="leave()" class="zch-leave-button" v-bind:style="menuStyle">
        <img alt="" src="../../assets/close.svg" />
    </div>
</template>

<script>
import { stateHandler } from "../../main";

export default {
    name: "LeaveButton",
    props: {
        callback: String,
        menuStyle: String,
    },
    methods: {
        leave() {
            stateHandler.$emit(this.callback);
        },
    },
};
</script>

<style lang="scss" scoped>
.zch-leave-button {
    width: 34px;
    position: absolute;
    top: 44px;
    left: calc(100% - (34px + 44px));
    cursor: pointer;
    z-index: 2;
    transition: 500ms;

    &:hover {
        opacity: 0.6;
        transition: 500ms;
    }

    @media screen and (max-width: 992px) {
        top: 32px;
        left: calc(100% - (34px + 32px));
        position: fixed;
    }
}
</style>
