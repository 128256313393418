<template>
    <div class="zch-nav">
        <div class="zch-nav-links">
            <router-link
                v-for="(link, key) in navLinks"
                v-bind:key="key"
                v-bind:class="navLinkActive(link.name)"
                :to="{ name: link.name }"
                >{{ link.output }}</router-link
            >
        </div>
        <div class="zch-nav-links-mobile" v-bind:style="menuStyle" v-if="isNavigationOpened">
            <div class="zch-nav-links-mobile-logo" v-bind:style="menuStyle">
                <img src="../assets/logo.svg" alt="" />
            </div>
            <LeaveButton :callback="`onUserLeaveNav`" :menuStyle="menuStyle" />
            <router-link
                class="zch-nav-links-mobile-item"
                v-for="(link, key) in navLinks"
                v-bind:key="key"
                v-bind:class="navLinkActive(link.name)"
                :to="{ name: link.name }"
                >{{ link.output }}</router-link
            >
            <!--            <a class="zch-nav-login" href="/login">Login</a>-->
        </div>
        <div class="zch-nav-hamburger" @click="toggleNavigationMenu">
            <img src="../assets/hamburger-menu.svg" alt="" />
        </div>
        <!--        <a class="zch-nav-login mobile" href="/login">Login</a>-->
    </div>
</template>

<script>
import LeaveButton from "@/components/LeaveButton/LeaveButton";
import { stateHandler } from "../main";

export default {
    name: "Navigation",
    components: {
        LeaveButton,
    },
    data() {
        return {
            isNavigationOpened: false,
            navLinks: [
                { name: "Home", output: "Home" },
                { name: "FAQ", output: "FAQ" },
                { name: "AboutUs", output: "About Us" },
                { name: "Legal", output: "Legal" },
                { name: "Privacy", output: "Privacy" },
            ],
            menuStyle: "",
        };
    },
    methods: {
        toggleNavigationMenu() {
            this.isNavigationOpened = !this.isNavigationOpened;
        },
        navLinkActive(navlinkName) {
            return this.$route.name === navlinkName ? "active" : "";
        },
        handleEvents() {
            stateHandler.$on("onUserLeaveNav", () => {
                this.menuStyle = "margin-left: 100%";
                setTimeout(() => {
                    this.toggleNavigationMenu();
                    this.menuStyle = "";
                }, 1000);
            });
        },
    },
    mounted() {
        this.handleEvents();
    },
};
</script>

<style lang="scss" scoped>
.zch-nav {
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        justify-content: flex-end;
    }

    &-links {
        display: inherit;
        align-items: center;

        &-mobile {
            display: none;
            @media screen and (max-width: 768px) {
                position: absolute;
                display: flex;
                flex-direction: column;
                padding: 30% 0;
                justify-content: space-evenly;
                align-items: center;
                height: 100vh;
                background: url(../assets/monochrome-background.png) no-repeat, #fff;
                background-size: contain;
                background-position-x: right;
                background-position-y: bottom;
                width: 100vw;
                z-index: 1;

                top: 0;
                left: 0;

                transition: 0.1s;

                &-item {
                    font-family: Muli, sans-serif;
                    font-size: 22px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.24;
                    letter-spacing: normal;
                    color: #131313;
                    text-decoration: none;
                }
            }
            &-logo {
                position: fixed;
                top: 0;
                left: 0;
                max-width: 52px;
                margin: 32px;
            }
        }

        @media screen and (max-width: 768px) {
            display: none;
        }

        & > a {
            font-family: Muli, sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #131313;
            text-decoration: none;
            transition: 500ms;

            &:not(:first-child) {
                padding-left: 29px;
            }

            &.active {
                opacity: 0.4;
            }
            &:hover {
                transition: 350ms;
                opacity: 0.8;
            }
        }
    }

    &-hamburger {
        display: none;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            display: inherit;
            & > img {
                transition: 500ms;
                &:hover {
                    transition: 500ms;
                    opacity: 0.7;
                }
            }
        }
    }

    &-login {
        font-family: Muli, sans-serif;
        border-radius: 8px;
        border: solid 1px $color-montecarlo;
        background-color: #eafff8;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: $color-montecarlo;
        padding: 7px 34px;
        text-decoration: none;
        transition: background-color 500ms;

        &:hover {
            transition: background-color 500ms;
            opacity: 0.7;
        }
        @media screen and (max-width: 768px) {
            &.mobile {
                display: none;
            }
            width: 80%;
            text-align: center;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
        }
    }
}
</style>
